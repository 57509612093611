<template>
  <div>
    <b-card class="blog-edit-wrapper" :title="tituloForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- titulo -->
            <b-col cols="12">
              <b-form-group label-for="Titulo">
                 <label for="Titulo"
                        >Titulo <span class="text-danger">(*)</span></label
                      >
                
                <validation-provider
                  #default="{ errors }"
                  name="Titulo"
                  rules="required"
                >
                  <b-form-input
                    id="titulo"
                    v-model="titulo"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Titulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.titulo"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Estado" label-for="Estado">
                <v-select
                  id="Estado"
                  v-model="estadoa"
                  label="title"
                  :options="estadoOpciones"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Diseño" label-for="disenio">
                <v-select
                  id="disenio"
                  v-model="disenioa"
                  label="title"
                  :options="disenioOpciones"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-overlay :show="loading">
            <b-row>
              <b-col cols="12" class="mb-1">
                <span>Dimensiones sugeridas para slider principal: 1926 x 923</span><br/>
                <b-button
                  size="sm"
                  class="mr-1"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-primary"
                  @click="seleccionarImagenes"
                >
                  Subir Imagen / Video
                </b-button>
                <b-button
                  v-b-modal.modal-seleccionar-imagen
                  size="sm"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-primary"
                >
                  Elegir Imagen
                </b-button>
                <b-form-file
                  @change="subirImagenes"
                  v-model="imagenes"
                  accept="image/*, .mp4"
                  class="mt-1"
                  plain
                  hidden
                  id="imageUpload"
                />
              </b-col>

              <b-col cols="12">
                <draggable v-model="items" class="cursor-move row">
                  <b-col
                    v-for="(item, index) in items"
                    :key="index"
                    sm="6"
                    md="4"
                    lg="2"
                    cols="6"
                  >
                    <!-- :bg-variant="selected == index ? 'primary' : ''"
                      :text-variant="selected == index ? 'white' : ''"
                      @click="seleccionarArchivo(index)" 
                       v-model="selected"-->
                    <b-card
                      class="cursor-pointer"
                      no-body
                      border-variant="secondary"
                    >
                      <div class="box box1">
                        <div
                          v-if="isVideo(item.url)"
                          class="box-video img-thumbnail"
                        >
                          <video
                            playsinline=""
                            autoplay=""
                            loop=""
                            muted=""
                            style="
                              opacity: 1;
                              width: 100%;
                              height: 100%;
                              margin: 0;
                            "
                          >
                            <source :src="item.url" type="video/mp4" />
                            <source src="#" type="video/webm" />
                          </video>
                        </div>
                        <b-img-lazy
                          v-else
                          thumbnail
                          fluid
                          center
                          class="rounded w-100"
                          :src="item.url"
                          alt="Card image cap"
                        />
                      </div>
                      <!-- <b-card-text class="text-center text-truncate">
                        {{ item.titulo }}</b-card-text
                      > -->

                      <template #footer>
                        <b-button
                          v-b-modal.modal-opciones-slider
                          @click="mostrarOpciones(item)"
                          variant="flat-primary"
                          size="sm"
                        >
                          <span class="align-middle">Opciones</span>
                        </b-button>

                        <b-button
                          @click="removerImagen(index)"
                          variant="flat-danger"
                          size="sm"
                        >
                          <span class="align-middle">Remover</span>
                        </b-button>
                      </template>
                    </b-card>
                  </b-col>
                </draggable>
              </b-col>
            </b-row>
          </b-overlay>
          <b-row>
            <b-col md="12">
              <h3 class="pt-2"></h3>
            </b-col>
            <b-col md="12">
              <b-form-checkbox
                id="formulario"
                v-model="slidderactivo"
                value="1"
                unchecked-value="0"
                switch
              >
                <b>Activar Slider</b>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <!-- submit -->
          <b-row>
            <b-col cols="12" class="mt-50">
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                split
                text="Guardar"
                variant="primary"
                class="mr-1"
                @click="validar(), (guardarSalir = true)"
                right
              >
                <b-dropdown-item @click="validar(), (guardarSalir = false)">
                  Guardar y Editar
                </b-dropdown-item>
              </b-dropdown>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :to="{ name: 'sliders' }"
              >
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <!--Componente Imagen -->
      <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>

      <OpcionesSlider
        :accion="accion"
        :item="selected"
        @cerrarComponente="cerrarComponente"
      ></OpcionesSlider>
    </b-card>
  </div>
</template>
<script>
import axiosIns from "@/libs/axios";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormCheckbox,
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BImg,
  BFormFile,
  BDropdown,
  BDropdownItem,
  BContainer,
  BOverlay,
  BListGroupItem,
  BCardGroup,
  BImgLazy,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import { required, length } from "@validations";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import CkEditorPersonalizado from "@/componentes/CkEditorPersonalizado";

import EnlacesComponent from "@/componentes/EnlacesComponent.vue";
import OpcionesSlider from "@/views/apariencia/sliders/OpcionesSlider.vue";

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BImg,
    BFormFile,
    BContainer,
    BOverlay,
    BDropdown,
    BDropdownItem,
    ImagenComponent,
    vSelect,
    draggable,
    BListGroupItem,
    BCardGroup,
    BImgLazy,

    EnlacesComponent,
    CkEditorPersonalizado,
    OpcionesSlider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //datos de entrada
      selected: [],
      accion: "",
      id: null,
      titulo: "",
      descripcion: "",
      items: [],
      required,
      image: "",
      url: "",
      submitted: false,
      guardarSalir: true,
      errores: [],
      imagenes: [],
      imagen: [],
      loading: false,
      slidderactivo: 0,
      estadoOpciones: [
        { id: 1, title: "Publicado" },
        { id: 2, title: "Borrador" },
        { id: 3, title: "Papelera" },
      ],
      disenioOpciones: [
        {
          id: 1,
          title: "Slider Principal",
        },
        {
          id: 2,
          title: "Slider Secundario",
        },
      ],
      disenioa: { id: 1, title: "Slider Principal" },
      estadoa: { id: 2, title: "Borrador" },
    };
  },

  computed: {
    tituloForm() {
      return this.id ? "Editar Slider" : "Nuevo Slider";
    },
  },
  watch: {
    $route: function (val) {
      if (val.params.id) {
        this.editar(this.$route.params.id);
      } else {
        this.limpiar();
      }
    },
  },
  created() {},
  destroyed() {},
  methods: {
    isVideo(url) {
      if (url.indexOf(".mp4") > 0) return true;
      else return false;
    },
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },

    // mostrarItem(item) {
    //   console.log(item);
    // },
    mostrarOpciones(item) {
      (this.accion = "mostrar"), (this.selected = item);
      //console.log(item);
    },
    cerrarComponente() {
      //this.accion='';
      this.item = [];
      this.accion = "";
      //this.actualizarLista();
    },

    async subirImagenes(event) {
      this.loading = true;
      if (event.target.files.length) {
        let file = event.target.files;
        let formData = new FormData();
        //formData.append('imagenes', this.imagenes);
        //formData.append('imagenes', JSON.stringify(this.imagenes));
        //formData.append("tipo", "IMAGEN");
        formData.append("mediosCategoria_id", 1);
        formData.append("enlace", 0);
        for (let i = 0; i < file.length; i++) {
          formData.append("imagenes[]", file[i]);
        }
        await axiosIns
          .post("/sliders/subir-imagenes", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            /*onUploadProgress (e) {
                  console.log(e)
                  this.bar = Math.round((e.loaded * 100.0) / e.total);
                  console.log(this.bar)
                }*/
          })
          .then((res) => {
            this.loading = false;
            let resItem = res.data.map((g) => ({
              id: g.id,
              url: g.url,
              titulo: g.titulo,
              peso: g.peso,
              index: "",
              contenido: "",
              texto1: "",
              texto2: "",
              color1: "#000000",
              color2: "#000000",
              imagen_miniatura: "",
              url_slider: "",
              isurl_text_1: 0,
              isurl_text_2: 0,
              isurl_imagen: 0,
              aplicarurl: [],
            }));
            this.items = this.items.concat(resItem);
            this.imagenes = [];
            this.errores = [];
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },

    seleccionarImagenes() {
      document.getElementById("imageUpload").click();
    },

    removerImagen(index) {
      this.items.splice(index, 1);
    },
    recibirImagenPortada(imagen) {
      this.image = imagen.url;
    },

    recibirImagen(imagen) {
      let resItem = {
        id: imagen.id,
        url: imagen.url,
        titulo: imagen.titulo,
        peso: imagen.peso,
        index: "",
        texto1: "",
        texto2: "",
        color1: "#000000",
        color2: "#000000",
        imagen_miniatura: "",
        url_slider: "",
        isurl_text_1: 0,
        isurl_text_2: 0,
        isurl_imagen: 0,
        aplicarurl: [],
      };
      /*let resItem = imagen.map(g => ({
          id:g.id,
          url:g.url,
          titulo:g.titulo,
          peso:g.peso,
      }));*/
      this.items = this.items.concat(resItem);
    },

    //REGISTRO DE slider
    registrar() {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].index = i;
      }
      axiosIns
        .post("/sliders", {
          titulo: this.titulo,
          estado: this.estadoa.id,
          activo: this.slidderactivo,
          disenio: this.disenioa.title,
          items: this.items,
        })
        .then((res) => {
         // console.warn("respuesta");
         // console.log(res);
          this.$bvToast.toast("Exito", {
            title: "Slider se guardó con éxito",
            variant: "primary",
            solid: false,
          });
          if (this.guardarSalir) {
            this.$router.push({ name: "sliders" });
          } else {
            this.id = res.data;
            this.$router.push({
              name: "slider-editar",
              params: { id: res.data },
            });
            this.editar(this.id);
          }
          this.errores = [];
          this.submitted = false;
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    actualizar() {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].index = i;
      }
      axiosIns
        .put("/sliders/" + this.id, {
          titulo: this.titulo,
          estado: this.estadoa.id,
          activo: this.slidderactivo,
          disenio: this.disenioa.title,
          items: this.items,
        })
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Slider se guardo con exito",
            variant: "primary",
            solid: false,
          });
          if (this.guardarSalir) {
            this.$router.push({ name: "sliders" });
          } else {
            this.editar(this.id);
          }
          this.errores = [];
          this.submitted = false;
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    editar(id) {
      this.id = id;

      axiosIns
        .get("/sliders/" + this.id)
        .then((res) => {
          this.titulo = res.data.titulo;

          this.slidderactivo = res.data.activo;
          const valor = this.estadoOpciones.find(
            (estado) => estado.id === res.data.estado
          );
         // console.log(valor);
         // console.warn("edit");
          this.estadoa = { id: valor.id, title: valor.title };
        //  console.log(this.disenioOpciones);
          //console.log(res.data.disenio);
          const valord = this.disenioOpciones.find(
            (disenio) => disenio.title === res.data.disenio
          );

          this.disenioa = { id: valord.id, title: valord.title };
         // console.log(this.disenioa);
          this.items = res.data.slideritems.map((g) => ({
            id: g.id,
            url: g.urlimagen,
            index: g.index,
            texto1: g.texto1,
            texto2: g.texto2,
            color2: g.color2,
            color1: g.color1,
            imagen_miniatura: g.imagen_miniatura,
            isurl_text_1: g.isurl_text_1,
            isurl_text_2: g.isurl_text_2,
            isurl_imagen: g.isurl_imagen,
            url_slider: g.url_slider,
            aplicarurl: [],
          }));
          // console.log("holasss");
          // console.log(this.items);
          // console.log("itmes");
          //this.items=res.data.galeriaItem;
        })
        .catch((err) => {});
    },
    limpiar() {
      this.id = null;
      this.titulo = "";
      this.descripcion = "";
      this.items = [];
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.editar(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.scroll-area1 {
  position: relative;
  margin: auto;
  width: 100%;
  padding: 0 1rem;
  height: calc(var(--vh, 1vh) * 100 - 20rem);
}
.card-size {
  width: 100%;
  height: 12rem;
}
.box {
  height: 12rem;
}
.box img {
  width: 100%;
  height: 100%;
}

.boxsv {
  height: calc(var(--vh, 1vh) * 100 - 19rem);
}
.boxsv img {
  width: 100%;
  height: 100%;
}
.boxsv1 img {
  object-fit: contain;
}
@media (max-width: 700px) {
  .boxsv {
    height: 15rem;
  }
}

.box-video {
  vertical-align: middle;
  height: 100%;
}
</style>
