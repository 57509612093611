<template>
  <b-modal
    id="modal-seleccionar-enlace"
    ref="my-modal"
    title="Selecione un Enlace"
    no-close-on-backdrop
    ok-title="Insertar"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    size="xl"
    @cancel="cerrarModal"
    @close="cerrarModal"
    :ok-disabled="selected == ''"
    @ok.prevent="validarSeleccionar"
  >
    <b-tabs>
      <b-tab lazy title="Entradas">
        <Entradas @selectItem="selectItemMedia" />
      </b-tab>
      <b-tab lazy title="Noticias">
        <Noticias @selectItem="selectItemMedia" />
      </b-tab>
      <b-tab lazy title="Convocatorias">
        <Convocatorias @selectItem="selectItemMedia" />
      </b-tab>
      <b-tab lazy title="Paginas">
        <Paginas @selectItem="selectItemMedia" />
      </b-tab>
      <b-tab lazy title="Eventos">
        <Eventos @selectItem="selectItemMedia" />
      </b-tab>
      <!-- <b-tab lazy title="Medios">
        <Medios @selectItem="selectItemMedia" />
      </b-tab> -->
    </b-tabs>
    <b-row>
      <!-- titulo -->
      <b-col md="6" cols="12">
        <b-form-group label="Titulo" label-for="Titulo">
          <b-form-input
            id="titulo"
            v-model="selected.titulo"
            placeholder="Titulo"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" cols="12">
        <b-form-group label="Url" label-for="Url">
          <b-form-input
            id="url"
            disabled
            v-model="selected.url"
            placeholder="Url"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import axiosIns from "@/libs/axios";
import {
  BTabs,
  BTab,
  BCardText,
  BButton,
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import Entradas from "@/componentes/Entradas.vue";
import Noticias from "@/componentes/Noticias.vue";
import Convocatorias from "@/componentes/Convocatorias.vue";
import Medios from "@/componentes/Medios.vue";
import Paginas from "@/componentes/Paginas.vue";
import Eventos from "@/componentes/Eventos.vue";

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BTab,
    BTabs,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    Entradas,
    Noticias,
    Convocatorias,
    Medios,
    Paginas,
    Eventos,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      selected: { url: "", titulo: "" },
    };
  },
  created() {},
  watch: {},
  methods: {
    cerrarModal() {
      this.selected = "";
    },
    selectItemMedia(item) {
      //this.selected=item[0];
      if (item.url_admin) {
        var obj = new Object();
        obj.url = item.url_admin;
        obj.titulo = item.titulo;
        this.selected = obj;
        //this.selected=item;
        //console.log(this.selected);
      }
    },
    validarSeleccionar() {
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
      this.$emit("enviarUrl", this.selected);
    },
  },
};
</script>
<style lang="scss">
#scroll {
  .scroll-area1 {
    height: calc(var(--vh, 1vh) * 100 - 23rem) !important;
    overflow: hidden;
  }
}
#modal-seleccionar-enlace {
  .modal-body {
    padding: 0rem !important;
  }
  .card {
    margin-bottom: 0rem !important;
  }
  .card-body {
    padding: 1rem;
  }
  #card-media {
    margin-bottom: 2rem !important;
  }
}
</style>



