<template>
  <b-card >
    <b-tabs v-model="tabIndex">
      <b-tab lazy>
        <template #title>
          <feather-icon size="17" icon="ImageIcon" />
          <span class="d-none d-sm-block">Imágenes</span>
        </template>
        <Imagen @selectItem="selectItemImagen" :tipo="tipo" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon size="17" icon="FileIcon" />
          <span class="d-none d-sm-block">Documentos</span>
        </template>
        <Documento @selectItem="selectItemDocumento" :tipo="tipo" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon size="17" icon="VideoIcon" />
          <span class="d-none d-sm-block">Videos</span>
        </template>
        <Video @selectItem="selectItemVideo" :tipo="tipo" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon size="17" icon="Volume2Icon" />
          <span class="d-none d-sm-block">Audios</span>
        </template>
        <Audio @selectItem="selectItemAudio" :tipo="tipo" />
      </b-tab>
      <!--<b-tab lazy>
        <template #title>
          <feather-icon size="17" icon="TrendingUpIcon" />
          <span class="d-none d-sm-block">Estadisticas</span>
        </template>
        <b-card-text>
          Estadisticas
        </b-card-text>
      </b-tab>-->
    </b-tabs>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import { BTabs, BTab, BCardText, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import Imagen from "@/views/aplicaciones/medios/imagenes/Imagen.vue";
import Documento from "@/views/aplicaciones/medios/documentos/Documento.vue";
import Video from "@/views/aplicaciones/medios/videos/Video.vue";
import Audio from "@/views/aplicaciones/medios/audios/Audio.vue";

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    BCard,
    Imagen,
    Documento,
    Video,
    Audio,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: "",
      tabIndex: 0,
      tipo: "IMAGEN",
    };
  },
  created() {},
  watch: {
    tabIndex: function(val) {
      if (val == 0) {
        this.tipo = "IMAGEN";
      } else if (val == 1) {
        this.tipo = "DOCUMENTO";
      } else if (val == 2) {
        this.tipo = "VIDEO";
      } else if (val == 3) {
        this.tipo = "AUDIO";
      } else if (val == 4) {
        this.tipo = "ESTADISTICA";
      }
    },
  },
  methods: {
    selectItemImagen(item){
      var selected=[];
      selected.push(item);
      this.$emit('selectItem',selected);
      //console.log('imagen: '+item)
    },
    selectItemDocumento(item){
      var selected=[];
      selected.push(item);
      this.$emit('selectItem',selected);
      //console.log('documento: '+item)
    },
    selectItemVideo(item){
      var selected=[];
      selected.push(item);
      this.$emit('selectItem',selected);
      //console.log('video: '+item)
    },
    selectItemAudio(item){
      var selected=[];
      selected.push(item);
      this.$emit('selectItem',selected);
      //console.log('audio: '+item)
    },
  },
};
</script>
<style lang="scss"></style>
